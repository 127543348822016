import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlayerCreateDTO, createPlayerProfile, updatePlayerURLToId } from "../services/PlayerProfileService";
import { httpsCallable } from "firebase/functions";
import { storage, functions } from "..";
import { FactModelCreateDTO, createFacts, getAllFactsForPlayer } from "../services/FactService";
import { ScoreCreateDTO, createScore } from "../services/ScoreService";
import { Box, TextField, Avatar, Button } from "@mui/material";
import { DefaultAvatarButton } from "./buttons/DefaultAvatarButton";
import { CameraButton } from "./buttons/PhotoButton";
import { PhotoPromptDialog } from "./dialogs/PhotoPromptDialog";
import { PlayerFactSummary } from "../services/FactGenerationService";
import { LangContext } from "../context/lang";
import { getIdentityTypeState } from "../services/GameStatesService";

interface PlayerProfileCreationProps {
  gameId: string;
  numberOfFacts: number;
  isPremadeProfile: boolean;
  handleStoreAndClose?: (playerFact: PlayerFactSummary) => void;
}

interface IdentityFormProps {
  identityType: string;
  name: string
  handleOnChangeNameInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  translate: (key: string) => string;
}

const IdentityForm = (props: IdentityFormProps) => {
  if (props.identityType === "fullname") {
    return (
      <TextField
        sx={{
          "& label.Mui-focused": {
            color: "black",
          },
          minWidth: 160
        }}
        id="player-name"
        label={props.translate("create-profile-name")}
        type="string"
        variant="filled"
        onChange={(e) => props.handleOnChangeNameInput(e)}
        value={props.name}
      />
    )
  }
  else if (props.identityType === "number") {
    return (
      <TextField
        sx={{
          "& label.Mui-focused": {
            color: "black",
          },
        }}
        id="player-number"
        label={props.translate("create-profile-number")}
        type="number"
        variant="filled"
        onChange={(e) => props.handleOnChangeNameInput(e)}
        value={props.name}
      />
    )
  }
  return null
}

export const PlayerProfileCreation = (props: PlayerProfileCreationProps) => {
  const {
		dispatch: { translate },
	} = useContext(LangContext);
  const navigate = useNavigate();

  // Page Control States
  const [identityType, setIdentityType] = useState("");

  // Facts Constants
	const emptyFacts: Array<string> = [];

  // Image Constants
	const types = ["image/png", "image/jpeg"];

  // Rendering States
	const [isPromptingPhoto, setIsPromptingPhoto] = useState(false);

  // Form Submit States
	const [name, setName] = useState("");
	const [facts, setFacts] = useState(emptyFacts);
	const [hasSubmittedFact, setHasSubmittedFact] = useState(false);

  // Image States
	const [file, setFile] = useState<File>();
	const [imagePreview, setImagePreview] = useState("");

  // Rendering Constants
  const paddingClass = () => (props.isPremadeProfile ? '' : 'overflow-protection')

  // Event Handlers
  function handlePictureSelection(e: { target: { files: any } }) {
		const file = e.target.files[0];
		if (file && types.includes(file.type)) {
			const fileURL = URL.createObjectURL(file);
			setImagePreview(fileURL);
			setFile(file);
		}
	}

  function handleIconSelection(value: string) {
		console.log(`Icon Selected: ${value}`);
		setImagePreview(value);
	}

  function handleOnChangeFactInput(
		index: number,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		const factValue = e.target.value;
		const clonedFacts = [...facts];
		clonedFacts[index] = factValue;
		setFacts(clonedFacts);
	}

  function handleOnChangeNameInput(
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		const nameValue = e.target.value;
    if (identityType === 'number' && nameValue.length > 4) {
      return;
    }
		setName(nameValue);
	}

  function handleProceedWithoutPhoto() {
		setIsPromptingPhoto(true);
		submitPlayerDetails();
	}

  function handlePlayerDetailsSubmitButton() {
		if (imagePreview !== "/broken-image.jpg") {
			setHasSubmittedFact(true);
			submitPlayerDetails();
		} else {
			setIsPromptingPhoto(true);
		}
	}

  // Saving Profile Data
  async function uploadPicture() {
		if (file) {
			const fileType = file.type as string;
			const splitFileType = fileType.split("/");
			const fileExtension = splitFileType[splitFileType.length - 1];
			const storageRef = ref(
				storage,
				`Profile_pictures/${props.gameId}/${name}.${fileExtension}`
			);
			try {
				const uploadResult = await uploadBytes(storageRef, file);
				const downloadURL = await getDownloadURL(uploadResult.ref);
				return downloadURL;
			} catch (error) {
				console.log(`Unable to upload picture for file: ${file}`);
			}
		}
	}

  async function saveProfileInformation() {
		let savedImage = imagePreview;

		try {
			if (file) {
				const newImage = await uploadPicture();
				if (newImage) {
					savedImage = newImage;
				}
			}
	
			const playerDetails: PlayerCreateDTO = {
				name: name,
				picture: savedImage,
        isPremadeProfile: props.isPremadeProfile
			};
	
			const playerId = await createPlayerProfile(props.gameId, playerDetails);
	
			if (playerId) {
				localStorage.setItem("url", playerId)
				await updatePlayerURLToId(props.gameId, playerId);
				const factDetails: FactModelCreateDTO = {
					playerId: playerId,
					playerName: name,
					playerPicture: savedImage,
					facts: facts,
          isPremadeFact: props.isPremadeProfile 
				};
				await createFacts(props.gameId, factDetails);
	
				const scoreDetails: ScoreCreateDTO = {
					playerId: playerId,
					playerName: name,
					playerPicture: savedImage,
					score: 0,
				};
        if (!props.isPremadeProfile) {
          await createScore(props.gameId, scoreDetails);
        } else {
          const playerFacts = await getAllFactsForPlayer(props.gameId, playerId)
          const playerFactSummary: PlayerFactSummary = {
            playerId: playerId,
            playerName: name,
            playerFacts: playerFacts,
            playerPicture: savedImage,
            isPremade: props.isPremadeProfile
          }
          if (props.handleStoreAndClose) {
            props.handleStoreAndClose(playerFactSummary);
          }
        }
				} else {
				throw new Error(`Player Id could not be created - Player: ${playerDetails.name} - GameId: ${props.gameId}`)
			}
		} catch(error) {
			if (error instanceof Error) {
				const addError = httpsCallable(functions, 'addError');
				await addError(error.message)
			}
		}
	}

  async function submitPlayerDetails() {
		localStorage.clear();
    await saveProfileInformation();
    if (!props.isPremadeProfile) {
      navigate(`/${props.gameId}/waiting-room`);
    }
	}

  // Validation
	function areThereEmptyFields() {
		let fieldEmpty = false;

		for (const fact of facts) {
			if (!fact) {
				fieldEmpty = true;
				break;
			}
		}

		if (!name) {
			fieldEmpty = true;
		}
		return fieldEmpty;
	}

  function answerText(index: number) {
    if (identityType === "fullname") {
      return `${translate("create-profile-fact")}${
        index + 1
      }`
    }
    else if (identityType === "number") {
      return translate("identity-type-name")
    }
  }

  useEffect(() => {
    function initializeFacts() {
      setImagePreview("/broken-image.jpg");
      const factList: Array<string> = [];
      for (let i = 0; i < props.numberOfFacts; i++) {
        factList.push("");
      }
      setFacts(factList);
    }
    async function initializeIdentityType() {
      const fetchedGameIdentity = await getIdentityTypeState(props.gameId)
      setIdentityType(fetchedGameIdentity)
    }
    initializeFacts()
    initializeIdentityType()
  }, [props.numberOfFacts])

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& .MuiTextField-root": {
            m: 1,
            width: "25ch",
            backgroundColor: 'white'
          },
        }}
        noValidate
        autoComplete="off"
      >
        <IdentityForm
          identityType={identityType}
          name={name}
          handleOnChangeNameInput={handleOnChangeNameInput}
          translate={translate}
        />
        {facts.map((value, index) => (
          <div key={index}>
            <TextField
              sx={{ "& label.Mui-focused": { color: "black" } }}
              id="player-facts"
              label={answerText(index)}
              type="string"
              variant="filled"
              value={value}
              onChange={(e) => handleOnChangeFactInput(index, e)}
            />
          </div>
        ))}
      </Box>
      <Box
        className={paddingClass()}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <Avatar
          src={imagePreview}
          sx={{ width: 50, height: 50, alignItems: "center" }}
        />
        <Box
          sx={{
            display: "flex",
            width: "200px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        >
          <CameraButton isPremadeProfile={props.isPremadeProfile} handlePictureSelection={handlePictureSelection} />
          <DefaultAvatarButton isPremadeProfile={props.isPremadeProfile} onImageSet={handleIconSelection} />
        </Box>
        <Button
          sx={{ color: "white", marginTop: "8px" }}
          id="factButton"
          variant="contained"
          onClick={handlePlayerDetailsSubmitButton}
          disabled={areThereEmptyFields() || hasSubmittedFact}
        >
          {translate("create-prfile-submit")}
        </Button>
      </Box>
      <PhotoPromptDialog
        open={isPromptingPhoto}
        onReturn={() => setIsPromptingPhoto(false)}
        onProceedWithoutSelfie={handleProceedWithoutPhoto}
      />
    </>
  );
}